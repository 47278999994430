html { scroll-behavior: smooth; }

.grid { display: grid;}
.rw-grid { grid-template-rows: 2rem 1fr; }
.header-grid { grid-template-columns: 1fr 6rem; }
.form-grid { grid-template-rows: 1fr 2rem; }
.feedback-grid { 
    grid-template-columns: 7rem 1fr 1fr 1fr 1fr; 
    grid-auto-flow: row; 
}

.gc5 { grid-template-columns: repeat(5, 1fr);}

.sticky { position: sticky; }
.top-feedback { top : 0; }

.tracked-p { letter-spacing: .06em; }
.mt8 { margin-top: 12rem; }
.pt5-new { padding-top: 5rem; }

.misty-blue-deep { color: #264187; }
.misty-blue-mid { color: #5775A2; }
.misty-blue { color: #707F95; }
.misty-blue-medium { color: #a9b1bd; }
.misty-blue-light { color: #d2d7df; }
.dusty-grey-dark { color: #d3d3d5; }
.dusty-grey-medium { color: #e5e5e8; }
.dusty-grey-light { color: #f2f2f2; }
.stone { color: #999; }
.grey { color: #777; }
.mid-stone { color: #333; }
.moss-green-dark { color: #9a9f87; }
.moss-green-medium { color: #c0c3b2; }
.moss-green-light { color: #dbddd2; }
.earthy-stone-dark { color: #b0a9a2; }
.earthy-stone-medium { color: #cecbc8; }
.earthy-stone-light { color: #e6e4e3; }
.earthy-red { color: #980000; }
.blush { color: #CF8C8C; }
.dark-blush { color: #BF6E6E; }
.light-cream { color: #E8C8AC; }
.medium-cream { color: #D9A87E; }
.light-violet { color: #AC8A96; }


.bg-misty-blue-deep { background-color: #264187; }
.bg-misty-blue-mid { background-color: #5775A2; }
.bg-misty-blue-dark { background-color: #707f95; }
.bg-misty-blue-medium { background-color: #a9b1bd; }
.bg-misty-blue-light { background-color: #d2d7df; }
.bg-dusty-grey-dark { background-color: #d3d3d5; }
.bg-dusty-grey-medium { background-color: #e5e5e8; }
.bg-dusty-grey-light { background-color: #f2f2f2; }
.bg-moss-green-dark { background-color: #9a9f87; }
.bg-moss-green-medium { background-color: #c0c3b2; }
.bg-moss-green-light { background-color: #dbddd2; }
.bg-earthy-stone-dark { background-color: #b0a9a2; }
.bg-earthy-stone-medium { background-color: #cecbc8; }
.bg-earthy-stone-light { background-color: #e6e4e3; }
.bg-dusty-grey-light-cthru { background-color: rgba(242, 242, 242, 0.2); }
.bg-dusty-grey-light-40 { background-color: rgba(242, 242, 242, 0.4); }
.bg-dusty-grey-light-60 { background-color: rgba(242, 242, 242, 0.6); }
.bg-earthy-stone-light-40 { background-color: rgba(230, 228, 227, 0.4); }
.bg-earthy-stone-light-60 { background-color: rgba(230, 228, 227, 0.6); }
.bg-earthy-stone-light-80 { background-color: rgba(230, 228, 227, 0.8); }
.bg-blush { background-color: #CF8C8C; }
.bg-blush-medium { background-color: #DDB1B1; }
.bg-blush-light { background-color: #EED6D6; }
.hover-bg-blush:hover { background-color: #BF6E6E; }
.hover-bg-misty-blue:hover { background-color: #707f95; }
.hover-bg-misty-blue-mid:hover { background-color: #5775A2; }
.hover-bg-misty-blue-deep:hover { background-color: #264187; }

.hover-blush:hover { color: #BF6E6E; }
.hover-mountain:hover { color: #999; }
.hover-misty-blue-mid:hover { color: #5775A2; }
.hover-misty-blue-deep:hover { color: #264187; }
.hover-bloom:hover { color: #BF6E6E; }


.gafc { grid-auto-flow: column; }
.gafr { grid-auto-flow: row; }
.gc1 {grid-template-columns: 100%}
.gc2 {grid-template-columns: 50% 50%}
.gc3 {grid-template-columns: 33.33333% 33.33334% 33.33333%}
.gac3 {grid-auto-columns: 3}
.gac5 {grid-auto-columns: 5}
.gc1 {grid-template-columns: 100%}
.gr1 {grid-template-rows: 100%}
.gr2 {grid-template-rows: 50% 50%}
.gra2 {grid-template-rows: auto auto}
.gca2 {grid-template-columns: auto auto}
.gcs1 {grid-column-start: 1}
.gcs2 {grid-column-start: 2}
.gcs3 {grid-column-start: 3}
.gcs4 {grid-column-start: 4}
.gcs5 {grid-column-start: 5}
.gce1 {grid-column-end: 1}
.gce2 {grid-column-end: 2}
.gce3 {grid-column-end: 3}
.gce4 {grid-column-end: 4}
.gce5 {grid-column-end: 5}
.grs1 {grid-row-start: 1}
.grs2 {grid-row-start: 2}
.grs3 {grid-row-start: 3}
.grs4 {grid-row-start: 4}
.grs5 {grid-row-start: 5}
.gre1 {grid-row-end: 1}
.gre2 {grid-row-end: 2}
.gre3 {grid-row-end: 3}
.gre4 {grid-row-end: 4}
.gre5 {grid-row-end: 5}
.gcg1 { column-gap: .25rem; }
.gcg2 { column-gap: .5rem; }
.gcg3 { column-gap: 1rem; }
.gcg4 { column-gap: 2rem; }
.gcg5 { column-gap: 4rem; }
.gcg6 { column-gap: 8rem; }
.gcg7 { column-gap: 16rem; }

.gai-start { align-items: start; }
.gai-center { align-items: center; }
.gai-end { align-items: end; }
.gji-start { justify-items: start; }
.gji-end { justify-items: end; }
.gjs-start { justify-self: start; }
.gjs-end { justify-self: end; }

@media screen and (max-width: 29.999999999em) {
    .smallheight {
    height: 100vh;
    height: calc(var(--vh, 1vh)*100 - 58px);
    }
    .smallminheight {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh)*100 - 58px);
    }
    .smallmaxheight {
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh)*100 - 58px);
    }
}

@media screen and (min-width: 30em) {
    .smallminheight-ns {
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh)*100 - 58px);
        }
    .gc1-ns {grid-template-columns: 100%}
    .gc2-ns {grid-template-columns: 50% 50%}
    .gc3-ns {grid-template-columns: 33.33333% 33.33334% 33.33333%}
    .gac3-ns {grid-auto-columns: 3}
    .gac5-ns {grid-auto-columns: 5}
    .gc1-ns {grid-template-columns: 100%}
    .gr1-ns {grid-template-rows: 100%}
    .gr2-ns {grid-template-rows: 50% 50%}
    .gcs1-ns {grid-column-start: 1}
    .gcs2-ns {grid-column-start: 2}
    .gcs3-ns {grid-column-start: 3}
    .gcs4-ns {grid-column-start: 4}
    .gcs5-ns {grid-column-start: 5}
    .gce1-ns {grid-column-end: 1}
    .gce2-ns {grid-column-end: 2}
    .gce3-ns {grid-column-end: 3}
    .gce4-ns {grid-column-end: 4}
    .gce5-ns {grid-column-end: 5}
    .grs1-ns {grid-row-start: 1}
    .grs2-ns {grid-row-start: 2}
    .grs3-ns {grid-row-start: 3}
    .grs4-ns {grid-row-start: 4}
    .grs5-ns {grid-row-start: 5}
    .gre1-ns {grid-row-end: 1}
    .gre2-ns {grid-row-end: 2}
    .gre3-ns {grid-row-end: 3}
    .gre4-ns {grid-row-end: 4}
    .gre5-ns {grid-row-end: 5}
    
}